footer {
    background-color: var(--dark-color-1) !important;
    height: 80px;
}

.footer__text {
    color: var(--dark-color-5);
    font-family: "Roboto-Regular";
    font-size: 14px;
}

.footer__link {
    color: var(--dark-color-5);
    font-family: "Roboto-Regular";
    font-size: 14px;   
}

.footer__social-icon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}