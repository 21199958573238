.company-jobs__filter-box {
    border-radius: var(--12, 12px);
    background: var(--dark-6, #E0E9ED);
    padding: 12px 20px;
    color: var(--dark-color-1);
    font-family: "Roboto-Condensed";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 25px;
}

.company-jobs__filter-box-title {
    width: 70px !important;
    padding-top: 5px;
    margin-left: -5px;
}

.select-filter {
    margin-right: 10px;
    height: 42px;
    border: none !important;
    padding-left: 0px !important;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.select-filter:hover {
    background-color: var(--dark-color-3) !important;
    color: #fff !important;
}

.select-filter::placeholder {
    color: var(--dark-color-3) !important;
}
  
.select-filter:hover::placeholder {
    color: #fff !important;
}

.company-jobs__list-row-box {
    border-radius: 12px;
    border: 1px solid var(--dark-color-5);
    margin-bottom: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    cursor: pointer;
}

.company-jobs__list-row-box-alt {
    border-radius: 12px;
    border: 1px solid var(--dark-color-5);
    margin-bottom: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    border-radius: 12px;
    background: var(--dark-color-7);
    cursor: pointer;
}

.company-jobs__list-job-id {
    color: var(--dark-color-1) !important;
    font-family: "Roboto-Condensed";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
}

.company-jobs__list-job-description {
    overflow: hidden;
    color: var(--dark-color-2) !important;
    text-overflow: ellipsis;
    font-family: "Roboto-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    padding-top: 15px;
}

.company-jobs__list-job-info {
    color: var(--dark-color-2) !important;
    font-family: "Roboto-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    padding-top: 15px;
}

.company-jobs__list-job-date-icon {
    width: 24px;
    height: 24px;
    margin-left: 15px;
    margin-right: 3px;
}
.company-jobs__list-job-date-user {
    width: 24px;
    height: 24px;
    margin-right: 3px;
}

.company-jobs__list-job-collection-title {
    color: var(--dark-color-1) !important;
    font-family: "Roboto-Condensed";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.company-jobs__list-job-collection-value {
    color: var(--dark-color-2) !important;
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */    
}

.company-jobs__filter-box-checkboxes {
    color: var(--dark-color-3) !important;

    font-family: "Roboto-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    /* padding-top: 10px; */
}



.checkbox-container {
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
  
  .checkbox-label {    
    color: var(--dark-color-3);
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */

    margin-top: 5px;
    margin-left: 10px;
    margin-right: 30px;

    cursor: pointer;
  }
  
  .checkbox-input {
    width: 24px; /* Adjust width as needed */
    height: 24px; /* Adjust height as needed */
    border-radius: 5px;
    margin-top: 6px;
    cursor: pointer;
  }
  
  /* Customize checkbox appearance */
  .checkbox-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color) !important;
    border: none !important;
  }
  
  .checkbox-input:focus {
    outline: none; /* Remove focus outline */
  }
  
  /* Optional: Hide default checkbox appearance */
  .checkbox-input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  /* Optional: Add custom checkbox style */
  .checkbox-input::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid var(--dark-color-4) !important;
    background-color: var(--dark-6, #E0E9ED);
    margin-right: 8px;
    border-radius: 5px;
  }
  
  .checkbox-input:checked::before {
    background-color: var(--primary-color);
    border-color: var(--primary-color) !important;
    border: none !important;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='5 12 9 16 19 6'%3e%3c/polyline%3e%3c/svg%3e");
    background-size: cover;
  }

  .company--view-job__bid-row {
    background-color: var(--dark-color-7);
    border-radius: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
  }