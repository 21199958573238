.companyheader__menu {
    color: var(--dark-color-4);
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
}

.companyheader__menu-selected {
    color: var(--dark-color-1);
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;    
}

.companyheader__menu-middle {
    padding-left: 30px;
    padding-right: 30px;
}


