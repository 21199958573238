img.sidebar__logo {
    padding-top: 10px;
    text-align: center;
    padding-bottom: 0px;
}
div.sidebar__logo-container {
    width:100%;
    margin-left: 10px;
}
.sidebar__container {
    background-color: #01314d !important;
    color: #B6BF00;    
    font-family: "Roboto-Bold";
    font-size: 16px;
}
.sidebar__hr {
    height: 1px;
    color: #ffffff;
    width: 100%;
    opacity: 20%;
    margin-bottom: 25px;
}
.nav-pills {
    color: #b7c3ca;
    padding: 12px;
    border-radius: 8px;
    padding-left: 20px;
}
.nav-pills-selected {
    background-color: var(--primary-color);
}
.sidebar__offers-icon {
    width:18.75px;
    height:19.41px;
    margin-right: 10px;
    margin-top: -3px;
    margin-left: 15px
}

.sidebar__copyright {
    font-family: "Roboto-Regular";
    font-size: 14px;    
    position: absolute;
    bottom: 20px;
}
.sidebar__link {
    cursor: pointer;
    position: absolute;
    left: 70px;
}
.sidebar__link-selected {
    position: absolute;
    color: #fff;
    left: 70px;
}