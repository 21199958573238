.profile--menu__header {
    color: var(--dark-color-1);
    font-family: "Roboto-Condensed";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.profile--menu__item {
    color: var(--dark-color-4) !important;
    font-size: 16px;
    font-family: "Roboto-Bold" !important;
    background-color: white;
    border: none;
    padding: 10px;
    width: 225px;
    text-align: left;
    border-radius: 12px;
}

.profile--menu__item:hover {
    background-color: var(--primary-light-color) !important;
    color: var(--primary-color) !important;
    border: none;
    padding: 10px;
}

.profile--menu__item-selected {
    background-color: var(--primary-light-color) !important;
    color: var(--primary-color) !important;
    border: none;
    padding: 10px;
}

.profile--form__header {
    color: var(--dark-color-1);
    font-family: "Roboto-Condensed";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 62.4px */
}

.profile--form__description {
    color: var(--dark-color-2);
    font-family: "Roboto-Light";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 24px */    
    padding-bottom: 25px;
}





