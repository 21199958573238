.login__background {
    background: #FFF;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .login__footer-text {
    color: var(--dark-color-3);
    text-align: center;
    
    /* paragraph/p3/regular */
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */    

    padding-top: 15px;
  }
  .login__footer-link {
    color: var(--dark-color-1);

    /* text/button_large */
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;    
  }
  
  .div-center {
    width: 500px;
    height: 500px;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 1em 2em;
    
    display: table;
    border-radius: 16px;
  }
  
  div.content {
    display: table-cell;
    vertical-align: middle;
  }

  .login__logo {
    padding-bottom: 20px;
  }
  h1.login__header {
    padding-bottom: 20px;

    color: var(--dark-color-1);
    text-align: center;
    
    /* headline/web/h1/bold */
    font-family: "Roboto-Condensed";
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 62.4px */    
  }
  
  .login__button {
    width: 100%;
    padding: 12px !important;
    margin-top: 10px;
  }
  .login_input {
    padding: 18px !important;
    margin-bottom: 15px;
    font-family: "Roboto-Regular" !important;
    font-size: 14px !important;
    border: 1px solid #7f949d !important;
  }

  input.login_input:focus {
    border: 1px solid var(--primary-color) !important;
    outline: none !important;
  }

  .login_input-wrapper {
    position:relative;
  }
  
  .login_input-icon {        
    padding: 4px;
    padding-left: 14px;
    position: absolute;
    box-sizing:border-box;
    top:50%;
    margin-top: -1px;
    left:2px;
    transform: translateY(-50%);    
  }
  
  .login_input-icon-after {        
    padding: 4px;
    padding-right: 14px;
    position: absolute;
    box-sizing:border-box;
    top:50%;
    margin-top: -1px;
    right:2px;
    transform: translateY(-50%);
  }

  .input {
    height: 50px;
    box-sizing:border-box;
    padding-left: 2.5rem;
  }

  .login__forgot-link {
    color: var(--dark-3, #60747E);
    text-align: right;
    font-family: "Roboto-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
  }

  .login__header-info-text {
    color: var(--dark-3, #60747E);
    text-align: center;
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */    
    margin-top: -10px;
    margin-bottom: 25px;
  }