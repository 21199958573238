.job--view-address {
    border-radius: var(--12, 12px);
    border: 1px solid var(--dark-color-6);
    background: #FFF;
    box-shadow: 0px 20px 30px 0px rgba(0, 49, 77, 0.05);  
    padding: var(--24, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--24, 24px);  

    margin-top: 20px;
  }
  .job--view-address-box {
    border-left: 1px solid var(--primary-color); 
  }

  .job--view-address-box-title {
    color: var(--dark-color-4);

    /* headline/web/h6/bold */
    font-family: "Roboto-Condensed";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    
    padding-bottom: 15px;
  }

  .job--view-address-hr {
    color: var(--dark-color-4);
    height: 2px;
    margin-left: 25px;    
  }
  .job--view-delivery-address-label {
    color: var(--dark-color-1);
    font-family: "Roboto-Condensed";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-top: 3px;
  }
  .job--view-delivery-address-value {
    color: var(--dark-color-2);
    font-family: "Roboto-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 10px 0px 10px 0px;
  }
  .job--view-delivery-note {
    color: var(--dark-color-4);
    font-family: "Roboto-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .job--view-bids-title {
    color: var(--dark-color-1);
    font-family: "Roboto-Condensed";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 52px */  
    padding-top: 35px;
    padding-bottom: 15px;
  }
  .job--view-timeline-title {
    color: var(--dark-1, #00314D);
    font-family: "Roboto-Condensed";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */  
    padding-top: 60px;
    padding-bottom: 35px;
  }

  .job--view-timeline-list-header {
    background: var(--primary-light-color);
    color: var(--dark-color-1);
    font-family: "Roboto-Condensed";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 16.9px */    

    padding-left: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .job--view-timeline-list-value {
    color: var(--dark-color-2);
    font-family: "Roboto-Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */

    padding-left: 12px;
    padding-top: 5px;
    padding-bottom: 8px;
  }

  .job--view-timeline-list {
    border-left: 1px solid var(--primary-color);
    padding-left: 3px;
  }
