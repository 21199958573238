.header--breadcrumbs {
    font-size: 14px;
    font-family: "Roboto-Bold" !important;
    color: var(--dark-color-1);
    padding-left: 36px !important;
    padding-top: 14px;
}
.header--breadcrumbs-first {
  color: var(--dark-color-4);
}

.header--user {
  cursor: pointer;
}

  .header--user-name {
    font-size: 16px;
    font-family: "Roboto-Condensed" !important;
    color: var(--dark-color-1);
    white-space: nowrap;
  }
  
  .header--user-email {
    font-size: 12px;
    font-family: "Roboto-Regular" !important;
    color: #60747E;
  }

  .header--user-avatar {
    height: 40px;
    width: 40px;
    margin-right: -10px;
  }

  .header--user-down {
    height: 7.4px;
    width: 12px;
    margin-top: 10px;
    margin-right: 15px;
  }

  .header--hr {
    background-color: var(--dark-color-6) !important;    
    height: 2px;
    width: 100%;
    margin-left: 20px;
  }

.dropdown {
  float:right;
  right: 175px;
  top: 65px;  
}

.dropdown-item:hover {
  color: var(--primary-main-color) !important;
  background-color: var(--primary-light-color) !important;
}

.dropdown-item {
  /* color: var(--primary-main-color) !important; */
  color: var(--dark-color-4) !important;
  font-size: 16px;
  font-family: "Roboto-Bold" !important;
}

.dropdown-item {
  /* background-color: var(--primary-light-color) !important; */
  background-color: white !important;
}

.dropdown-menu-arrow {
  top: -25px;
  left: 50%;
  width: 0;
  height: 0;
  position: relative;
}
.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}
.dropdown-menu-arrow:after {
  bottom: -18px;
  right: -8px;
  border-bottom-color: #fff;
}
.dropdown-menu-arrow:before {
  bottom: -17px;
  right: -8px;
  border-bottom-color: rgba(0,0,0,.15);
}

.dashboard-page__no-items-box {
  padding-top: 100px;
  margin-bottom: 100px;
}

.dashboard-page__no-items-title {
  color: var(--dark-color-1);
  text-align: center;

  /* headline/web/h1/bold */
  font-family: "Roboto-Condensed";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 62.4px */
}

.dashboard-page__no-items-subtitle {
  color: var(--dark-color-3);
  text-align: center;
  
  /* paragraph/p3/regular */
  font-family: "Roboto-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.dashboard-page--title {
    font-size: 38px;
    font-family: "Roboto-Condensed" !important;
    color: var(--dark-color-1);
    font-weight: 500 !important;
    padding-top: 25px !important;
    padding-bottom: 20px  !important;
}
.dashboard-page--title-status {
  color: var(--primary-main, #F65335);
  font-family: "Roboto-Condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.26px;
  text-transform: uppercase;

  border-radius: 100px;
  background: var(--primary-light, #FFF4F2);
  height: 25px;
  
  padding: 5px 25px 5px 25px;
  margin-top: 35px;
  margin-left: 20px;
}

.dashboard-page--description {
  font-family: "Roboto-Light" !important;
  font-size: 16px;
  color: var(--dark-color-2);
  margin-top: -20px;
  padding-bottom: 20px; 
}

.dashboard--view-item-header {
  color: var(--dark-color-4);
  font-family: "Roboto-Bold" !important;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: -10px;
}

.dashboard--view-item-header-backicon {
  width: 13.5px;
  height: 13.5px;
  margin-right: 10px;
  margin-top: -3px;
}
.dashboard--view-item-header-approved {
  background-color: var(--status-success-faded);
  background-blend-mode: multiply;
  padding: 10px 20px 10px 20px;
  border-radius: 120px;
  font-family: "Roboto-Bold" !important;
  font-size: 16px;
  color: var(--status-success);
  margin-right: 10px;
}
.dashboard--view-item-header-approved img {
  width: 13.5px;
  height: 10px;
  margin-right: 7px !important;
  margin-top: -2px !important;
}

.dashboard--view-documents-header {
  font-family: "Roboto-Condensed";
  font-weight: 500;
  font-size: 24px;
  color: var(--dark-color-1);
  margin-top: 15px;
  margin-bottom: 20px;
}





.dashboard--view-documents-box {
  border: 1px solid var(--dark-color-6);
  border-radius: 12px;
  color: var(--dark-color-1);
  font-family: "Roboto-Regular";
  font-weight: bold;
  font-size: 14px;
  position: relative;
  height: 145px !important;
  width: 145px !important;
  cursor: pointer;
  margin-bottom: 25px;
}
.dashboard--view-documents-box img {
  width: 44.5px;
  height: 52px;
  text-align: center !important;
  margin-left: 50px;
  margin-top: 30px;
}
.dashboard--view-documents-box-title {
  text-align: center !important;
  margin-top: 5px;
}

.dashboard--view-documents-image {
  margin: 25px;
  margin-top: 0px;
  margin-left: 15px;
  border: 1px solid black;
  width: 95%;
}

.dashboard--item-row {
  padding-top: 20px;  
  padding-bottom: 20px;  
  background-color: var(--dark-color-7);
  border-radius: 10px;
}
.dashboard--item-row-alt {
  padding-top: 20px;  
  padding-bottom: 20px;  
  background-color: #fff;
}

.dashboard--item-row-label {
  font-family: "Roboto-Condensed" !important;
  font-weight: 500;
  font-size: 16px;
  color: var(--dark-color-1);
  margin-bottom: 5px;
}

.dashboard--item-row-value {
  font-family: "Roboto-Regular" !important;
  font-size: 16px;
  color: var(--dark-color-2)
}

.modal-title {
  font-family: "Roboto-Condensed" !important;
  font-size: 35px !important;
  font-weight: 500 !important;
  padding-left: 4px;
}

.edit--form_group {
  /* border: 1px solid #B7C5CC;
  border-radius: 16px; */
  /* padding: 5px;
  padding-left: 20px;
  padding-right: 20px; */
  text-align: left;
}
.edit--form_group-label {
  font-family: "Roboto-Black";
  font-size: 10px;
  color: #80949D;
}
.edit--form_group-control {
  border: 0 !important;
  --webkit-appearance: none !important;
  margin-left: 0px !important;
  padding-left: 0px !important;

  font-family: "Roboto-Regular" !important;
  font-size: 18px !important;
  color: #00314D !important;  
}
.edit--form_group-control:focus {
  outline: none !important;
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

textarea:focus, input:focus{
  outline: none !important;
}
.edit--form_row {
  margin-bottom: 10px;
}
.edit-form__header {
  border-bottom: 0 !important;
  padding-top: 25px !important;
}
.edit-form__body {
  justify-content: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  font-family: "Roboto-Regular";
  font-size: 16px;
  color: var(--dark-color-2);
}
.edit-form__body p {
  padding: 20px !important;
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}
.edit-form__footer {
    justify-content: center !important;
    border-top: 0 !important;
    padding-top: 0px !important;
    padding-bottom: 30px !important;
}
.modal-header .btn-close {
  margin-top: -40px !important;
}

.add-item__row-header-number {
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 100px;
  text-align: center;
  font-family: "Roboto-Condensed";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */  
  padding-top: 5px;
  margin-right: 15px;
}

.add-item__row-header-number-active {
  background-color: var(--dark-color-1);  
  color: #fff;
}
.add-item__row-header-number-inactive {
  background-color: var(--dark-color-4);
  color: #fff;
}

.add-item__row-header {
  border-radius: var(--12, 12px);
  display: flex;
  padding: 15px 24px 15px 12px;
  align-items: center;
  font-family: "Roboto-Condensed";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */  
  margin-bottom: 20px;
}

.add-item__row-header-arrow {
  height: 7.5px;
  width: 12px;
  float: right;
}

.add-item__row-header-active {
  background: var(--dark-color-6);
  color: var(--dark-color-1);  
}
.add-item__row-header-inactive {
  background: var(--dark-color-7);
  color: var(--dark-color-4);
  cursor: pointer;
}

.add-item__row-radio-title {
  color: var(--dark-color-1);
  font-family: "Roboto-Condensed";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 20.8px */
  margin-bottom: -15px;
}

.add-item__row-radio-option {
  color: var(--dark-color-4);
  font-family: "Roboto-Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 7px;
  margin-right: 15px;
}

.add-item__summary-box {
  border-radius: var(--12, 12px);
  border: 1px solid var(--dark-6, #E0E9ED);
  background: #FFF;
  box-shadow: 0px 20px 30px 0px rgba(0, 49, 77, 0.05);
  display: inline-flex;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--24, 24px);
  width: 100%;
}
.add-item__summary-title {
  color: var(--dark-color-1);
  font-family: "Roboto-Condensed";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
  margin-bottom: -5px;
}
.add-item__summary-subtitle {
  color: var(--dark-color-1);
  font-family: "Roboto-Condensed";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 16.9px */
  margin-bottom: 5px;
}
.add-item__summary-value {
  color: var(--dark-color-3);
  font-family: "Roboto-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.add-item__summary-hr {
  height: 4px;
  width: 100%;
  background: var(--dark-color-6);
  color: var(--dark-color-6);
  margin-top: 0px;
}


textarea::placeholder {
  color: var(--dark-color-4) !important;
}
input::placeholder {
  color: var(--dark-color-4) !important;
}

.form_input {
  padding: 18px !important;
  margin-bottom: 15px;
  font-family: "Roboto-Regular" !important;
  font-size: 15px !important;
  /* padding-left: 2.5rem !important; */

  border: 1px solid #7f949d !important;
}

.form_input:focus {
  border: 1px solid var(--primary-color) !important;
}





.signup_input-wrapper { 
  position: relative;
  /* margin-top: 10px;
  margin-bottom: 10px; */
}

.signup--form_group-control {
  border: 0 !important;
  --webkit-appearance: none !important;
  margin-left: 0px !important;
  padding-left: 0px !important;

  font-family: "Roboto-Regular" !important;
  font-size: 18px !important;
  color: #00314D !important;  
}
.signup--form_group-label {
  top: 16px !important;
}

/* input:focus + label {
  transform: translateY(-25px);
  font-size: 12px;
}

input:not(:placeholder-shown) + label {
  transform: translateY(-25px);
  font-size: 12px;
  color: #000;
} */

textarea:focus + label {
  transform: translateY(-30px);
  font-size: 12px;
}

textarea:not(:placeholder-shown) + label {
  transform: translateY(-30px);
  font-size: 12px;
  color: #000;
}


.signup_input-wrapper input:focus + label,
.signup_input-wrapper input:not(:placeholder-shown) + label {
  transform: translateY(-30px);
  font-size: 12px;
}

.signup_input-wrapper label,
.signup_input-wrapper label {
  color: var(--dark-color-4);
}



.signup_input {
  padding: 18px !important;
  margin-bottom: 15px;
  font-family: "Roboto-Regular" !important;
  font-size: 18px !important;
  border: 1px solid #7f949d !important;
  height: 72px;
  border-radius: 12px !important;
}

input.signup_input:focus {
  border: 1px solid var(--primary-color) !important;
  outline: none !important;
}

textarea.signup_input:focus {
  border: 1px solid var(--primary-color) !important;
  outline: none !important;
}

select.signup_input:focus {
  border: 1px solid var(--primary-color) !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}


 .login_input-wrapper, .input-container { 
    position: relative;
    margin-bottom: 20px;
  }

  input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  label {
    position: absolute;
    top: 10px;
    left: 20px;
    transition: transform 0.3s ease-out, font-size 0.3s ease-out;
    background-color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    color: var(--dark-color-4);
    font-family: "Roboto-Regular";
    font-size: 18px;
    margin-top: 5px;
    pointer-events: none;
  }

  input:focus + label {
    transform: translateY(-25px);
    font-size: 12px;
    color: var(--primary-color) !important;
  }

  input:not(:placeholder-shown) + label {
    transform: translateY(-25px);
    font-size: 12px;
    color: #000;
  }

  textarea:focus + label {
    transform: translateY(-30px);
    font-size: 12px;
    color: var(--primary-color) !important;
  }

  textarea:not(:placeholder-shown) + label {
    transform: translateY(-30px);
    font-size: 12px;
    color: #000;
  }

  select:focus + label {
    transform: translateY(-30px);
    font-size: 12px;
    color: var(--primary-color) !important;
  }

  select:not(:placeholder-shown) + label {
    transform: translateY(-30px);
    font-size: 12px;
    color: #000;
  }

/* Apply italics to the invalid options too when shown in the dropdown */
select option[value=""], select option:not([value]) + label {
  transform: translateY(-30px);
  font-size: 12px;
  color: #000;
}

.radio-label {
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  color: var(--dark-color-4);
  font-family: "Roboto-Regular";
  font-size: 38px;
}


.modal_subtitle {
  color: var(--dark-color-1);
  font-family: "Roboto-Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 20.8px */ 
}

.modal_subtitle-value {
  color: var(--dark-color-2);
  font-family: "Roboto-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}


.signup_input-wrapper-title-bold {
  color: var(--dark-color-1);
  font-family: "Roboto-Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 20.8px */    
}

.signup_input-wrapper-title {
  color: var(--dark-color-3);
  font-family: "Roboto-Condensed";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.dashboard-page--info-pill {
  background-color: var(--dark-color-5);
  border-radius: 100px;
  font-family: "Roboto-Condensed" !important;  
  font-weight: 500;
  font-size: 12px;
  padding: 5px 10px 5px 10px;  
  width: 75px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-right: 5px;

  margin-top: 5px !important;
  display: inline-block;
  margin-left: 15px;
}

.dashboard-page--info-pill-value {
  color: var(--dark-color-2);
  font-family: "Roboto-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  /* padding-top: 100px !important; */

  margin-top: 5px !important;
  display: inline-block;
  
}