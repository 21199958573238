.company-view-job__driver-list-box {
    background-color: var(--dark-color-7);
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 12px;    
    cursor: pointer;
    border: 1px solid var(--dark-color-7);
}

.company-view-job__driver-list-box-alt {
    background-color: white;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 12px;    
    cursor: pointer;
    border: 1px solid white;
}

.company-view-job__driver-list-box-alt:hover {
    background-color: white;
    border: 1px solid red;
}

.company-view-job__driver-list-box:hover {
    background-color: white;
    border: 1px solid red;
}
