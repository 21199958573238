.dashboard-table {
    width: 100%;    
  }

  .dashboard-table--header {
    font-size: 16px;
    font-family: "Roboto-Condensed" !important;
    color: var(--dark-color-1);
    font-weight: 500;    
    background-color: var(--dark-color-6);
    padding: 10px; 
    border-radius: 20px !important;
  }

  .dashboard-table--header-row {
    background-color: var(--dark-color-6);    
  }

  .dashboard-table--header-column {
    padding: 10px;    
  }

  .dashboard-table--header-column-action {
    text-align: right;
    padding-right: 15px;
  }

  .dashboard-table--row {
    height: 61px;
  }

  .dashboard-table tr:first-child th:first-child { border-top-left-radius: 10px; }
  .dashboard-table tr:first-child th:last-child { border-top-right-radius: 10px; }
  .dashboard-table tr:last-child th:first-child { border-bottom-left-radius: 10px; }
  .dashboard-table tr:last-child th:last-child { border-bottom-right-radius: 10px; }
  .dashboard-table tr:first-child th { border-top-style: solid; }
  .dashboard-table tr th:first-child { border-left-style: solid; }

  .dashboard-table--body-column {
    font-size: 16px;
    font-family: "Roboto-Regular" !important;
    color: var(--dark-color-2);
    padding: 10px; 
  }

.dashboard-table--body-column-actions {
  text-align: right;
}

.dashboard-table tr:nth-child(even) {
   background-color:var(--dark-color-7);
}

.dashboard-table td:first-child {   border-top-left-radius: 10px; 
                                    border-bottom-left-radius: 10px; }
.dashboard-table td:last-child {    border-top-right-radius: 10px; 
                                    border-bottom-right-radius: 10px; }

.dashboard-table tr:hover {
    background-color: var(--dark-color-6);
    cursor: pointer;
}

.dashboard-table-nohover tr:hover {
  background-color: inherit;
  cursor: default;
}


.dashboard-table--action-icon {
  height: 42px;
  width: 42px;
  cursor: pointer;
}

.dashboard-table--body-column-label {
  background-color: var(--dark-color-6);
  border-radius: 100px;
  font-family: "Roboto-Condensed" !important;  
  font-weight: 500;
  font-size: 12px;
  padding: 5px 10px 5px 10px;
}

.pageLink {
  width: 32px;
  height: 32px;
  border: 1px solid var(--dark-color-5);
  border-radius: 50%;
  float: left;
  text-align: center;
  padding-top: 2px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Roboto-Medium" !important;  
  color: var(--dark-color-5)
}

.pageLink.active {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
}

.pageLink.disabled {
  border: 1px solid var(--primary-light-color);
  background-color: var(--primary-light-color);
  cursor: default;
}
