:root {
  --primary-color: #F65335;
  --primary-color-hover: #F65335;
  --dark-color-1: #01314d;
  --dark-color-2: #275068;  
  --dark-color-3: #60747E;
  --dark-color-4: #80949d;  
  --dark-color-5: #b7c5cc;
  --dark-color-6: #dee7ea;
  --dark-color-7: #f0f6f8;  

  --primary-light-color: #fff0e8;
  --primary-main-color: #ff5f00;

  --status-success: #17bc8c;
  --status-success-faded: rgba(23, 188, 140, .1);
}

@font-face {
  font-family: 'Roboto-Condensed';
  src: url('./assets/fonts/RobotoCondensed-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Black';
  src: url('./assets/fonts/Roboto-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/fonts/Roboto-Light.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  font-family: "Roboto-Bold" !important;
  font-size: 16px !important;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 120px !important;
}
.btn-primary:hover {
  background-color: #ff5c3e !important;
  border-color: #ff5c3e !important;
}

.btn-secondary {
  background-color: var(--primary-light-color) !important;
  border-color: var(--primary-light-color) !important;
  font-family: "Roboto-Bold" !important;
  font-size: 16px !important;
  width: 100%;
  color: var(--primary-color) !important;
  border-radius: 120px !important;
}
.btn-secondary:hover {
  background-color: #ff5c3e !important;
  border-color: #ff5c3e !important;
  color: var(--primary-light-color) !important;
}

.btn-simple {
  background-color: #fff !important;
  border-color: var(--dark-color-3) !important;
  font-family: "Roboto-Bold" !important;
  font-size: 16px !important;
  width: 100%;
  color: var(--dark-color-3) !important;
  border-radius: 120px !important;
}
.btn-simple:hover {
  background-color: var(--dark-color-3) !important;
  border-color: var(--dark-color-3) !important;
  color: #fff !important;
}


.btn-table-action {
  background-color: #fff !important;
  border-color: var(--primary-color) !important;
  font-family: "Roboto-Bold" !important;
  font-size: 16px !important;
  margin-right: 10px;
  color: var(--primary-color) !important;
  border-radius: 120px !important;
}
.btn-table-action:hover {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff !important;
}

.btn-table-action-success {
  background-color: #fff !important;
  border-color: var(--status-success) !important;
  color: var(--status-success) !important;
}
.btn-table-action-success:hover {
  background-color: var(--status-success) !important;
  border-color: var(--status-success) !important;
  color: #fff !important;
}
